import React from 'react';
import { Heading, VStack, Grid, GridItem } from "@chakra-ui/react";
import { useState } from 'react';
import ImageUploader from "../common/ImageUploader";
import Navbar from '../common/Navbar';
import Footer from '../common/Footer';

export default function ScanRepas() {
    const [resetFunction, setResetFunction] = useState(null);

    const handleReset = (resetFunc) => {
        setResetFunction(() => resetFunc);
    };
    return (


        <>
            {/* Structure de la page */}
            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr auto'}
                gridTemplateColumns='1fr'
                color='blackAlpha.700'
                fontWeight='bold'
                bgGradient="linear(to-r, teal.500, blue.500)" // Utilisation du vert et bleu avec un gradient
                minHeight="100vh"
                w="100%" // Remplacer 100vw par 100%
                maxW="100%" // Remplacer 100vw par 100%
                overflowX="hidden" // Ajouter cette ligne pour désactiver le scroll horizontal
            >
                {/* Header (fixe avec Navbar) */}
                <GridItem area={'header'}>
                    <Navbar onResetScan={resetFunction} />
                </GridItem>

                {/* Section principale */}
                <GridItem area={'main'}
                    py={{ base: "8", lg: "16" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center" >
                    <VStack
                        spacing={6}
                        bg="white"
                        pb={{ base: '0', lg: "8" }}
                        borderRadius="md"
                        boxShadow="lg"
                        width="95vw"
                        maxWidth={{ base: '95vw', lg: "92vw", xl: "1750px" }}
                    >
                        <Heading
                            as="h1"
                            fontSize={{ base: "2xl", lg: "3xl" }}
                            color="teal.600"
                            textAlign="center"
                            pt={6}
                        >
                            Commandes de repas
                        </Heading>
                        <ImageUploader onReset={handleReset} />
                    </VStack>
                </GridItem>

                {/* Footer */}
                <GridItem area={'footer'}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};
