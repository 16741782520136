import React, { useState } from 'react';
import { Box, Flex, Heading, List, ListItem, Text, Badge, Button, useToast } from '@chakra-ui/react';
import { jsPDF } from 'jspdf';

const TotalMeals = ({ mealsSummary }) => {
    const [isCopying, setIsCopying] = useState(false);
    const toast = useToast();

    // Fonction pour convertir une date française en objet Date
    const parseFrenchDate = (dateStr) => {
        const monthMap = {
            'janvier': '01',
            'février': '02',
            'mars': '03',
            'avril': '04',
            'mai': '05',
            'juin': '06',
            'juillet': '07',
            'août': '08',
            'septembre': '09',
            'octobre': '10',
            'novembre': '11',
            'décembre': '12'
        };

        // Parse "lundi 16 septembre" ou "lundi 7 octobre 2024"
        const [_, jour, mois, annee = new Date().getFullYear()] = dateStr.toLowerCase().split(' ');
        const moisNum = monthMap[mois];

        // Créer une date au format YYYY-MM-DD
        const dateObj = new Date(`${annee}-${moisNum}-${jour.padStart(2, '0')}`);
        return dateObj;
    };

    // Regrouper les plats ayant la même clé (date)
    const mergedMealsSummary = {};

    Object.keys(mealsSummary).forEach((date) => {
        if (!mergedMealsSummary[date]) {
            mergedMealsSummary[date] = {};
        }

        Object.keys(mealsSummary[date]).forEach((mealName) => {
            if (mergedMealsSummary[date][mealName]) {
                mergedMealsSummary[date][mealName] += mealsSummary[date][mealName];
            } else {
                mergedMealsSummary[date][mealName] = mealsSummary[date][mealName];
            }
        });
    });

    // Trier les dates chronologiquement
    const sortedDates = Object.keys(mergedMealsSummary).sort((a, b) => {
        const dateA = parseFrenchDate(a);
        const dateB = parseFrenchDate(b);
        return dateA - dateB;
    });

    // Calculer le total des repas commandés
    const totalMealsCount = sortedDates.reduce((total, date) => {
        const mealsForDate = mergedMealsSummary[date];
        const dateTotal = Object.values(mealsForDate).reduce((acc, quantity) => acc + quantity, 0);
        return total + dateTotal;
    }, 0);

    // Préparer les résultats sous forme de texte
    const generateEmailBody = () => {
        let body = "Voici le total des repas commandés :\n\n";
        sortedDates.forEach((date) => {
            body += `Date: ${date}\n`;
            Object.keys(mergedMealsSummary[date]).forEach((meal) => {
                body += `- ${meal}: ${mergedMealsSummary[date][meal]} plat(s)\n`;
            });
            body += '\n';
        });
        body += `\nTotal global des repas commandés : ${totalMealsCount}`;
        return body;
    };

    const copyToClipboard = async () => {
        setIsCopying(true);
        const emailBody = generateEmailBody();

        try {
            // Vérifier si l'API Clipboard est disponible
            if (!navigator.clipboard) {
                throw new Error('Le presse-papiers n\'est pas disponible');
            }

            // Demander la permission d'écriture si nécessaire
            if (typeof navigator.permissions !== 'undefined') {
                const permissionResult = await navigator.permissions.query({ name: 'clipboard-write' });
                if (permissionResult.state === 'denied') {
                    throw new Error('Permission d\'écriture dans le presse-papiers refusée');
                }
            }

            // Tenter de copier le texte
            await navigator.clipboard.writeText(emailBody);

            toast({
                title: "Succès !",
                description: "Le texte a été copié dans le presse-papiers.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Erreur lors de la copie :', error);

            // Méthode de secours avec un élément textarea
            try {
                const textarea = document.createElement('textarea');
                textarea.value = emailBody;
                document.body.appendChild(textarea);
                textarea.select();
                document.execCommand('copy');
                document.body.removeChild(textarea);

                toast({
                    title: "Succès !",
                    description: "Le texte a été copié dans le presse-papiers.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } catch (fallbackError) {
                console.error('Erreur lors de la méthode de secours :', fallbackError);
                toast({
                    title: "Erreur",
                    description: "Le texte n'a pas pu être copié. Veuillez réessayer.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } finally {
            setIsCopying(false);
        }
    };

    const downloadPDF = () => {
        const doc = new jsPDF();

        // Charger le logo à partir du dossier public
        const logoUrl = `${process.env.PUBLIC_URL}/AJScanRepasLogo512.jpg`;

        // Créer une image et l'ajouter au PDF
        const image = new Image();
        image.src = logoUrl;

        image.onload = function () {
            // Ajouter le logo tout en haut à gauche de la première page sans marge haute
            const logoWidth = 50;  // Largeur du logo
            const logoHeight = 50;  // Hauteur du logo
            doc.addImage(image, 'JPEG', 10, 0, logoWidth, logoHeight); // Position (x=10, y=0) pour supprimer la marge haute

            // Ajuster la taille de police pour le titre
            doc.setFont("helvetica", "bold");
            doc.setFontSize(18); // Taille de police légèrement plus grande pour le titre

            const pageWidth = doc.internal.pageSize.getWidth(); // Obtenir la largeur de la page
            let yPosition = logoHeight + 0;  // Position du titre juste en dessous du logo

            // Centrer le texte "Total des repas commandés" horizontalement
            doc.text("Total des repas commandés", pageWidth / 2, yPosition, { align: 'center' });

            yPosition += 15;  // Ajouter une marge sous le titre

            // Taille de police plus petite pour les repas
            doc.setFont("helvetica", "normal");
            doc.setFontSize(10); // Réduire la taille de police pour le contenu

            // Parcourir les dates et les repas
            sortedDates.forEach((date) => {
                doc.setFont("helvetica", "bold");
                // Utilisation de .toUpperCase() pour les dates en majuscules
                doc.text(`${date.toUpperCase()}`, 10, yPosition);
                yPosition += 8;  // Réduire l'espacement vertical entre les dates

                doc.setFont("helvetica", "normal");
                Object.keys(mergedMealsSummary[date]).forEach((meal) => {
                    doc.text(`- ${meal}: ${mergedMealsSummary[date][meal]} plat(s)`, 10, yPosition);
                    yPosition += 7;  // Réduire l'espacement vertical pour les repas
                    // Ajouter une nouvelle page si l'espace vertical dépasse la limite de la page
                    if (yPosition > 280) {
                        doc.addPage();
                        yPosition = 10;  // Revenir au début de la nouvelle page
                    }
                });

                yPosition += 5;  // Ajouter un espacement entre les groupes de dates
            });

            // Ajouter le total des repas commandés à la fin du PDF
            doc.setFont("helvetica", "bold");
            doc.setFontSize(14); // Taille légèrement supérieure pour le total
            doc.text(`Nombre de plats commandés : ${totalMealsCount}`, 10, yPosition + 10);

            // Télécharger le fichier PDF
            doc.save('TotalRepasCommandes.pdf');
        };
    };







    return (
        <Box bg="white" p={5} borderRadius="md" boxShadow="md" mt={5} w="100%" maxWidth={{ base: '90vw', lg: "1000px" }}>
            <Flex justifyContent="center" alignItems="center">
                <Heading as="h2" size="md" color="teal.600" mb={8}>
                    Total des Repas Commandés
                </Heading>
            </Flex>
            <List spacing={2}>
                {sortedDates.length === 0 ? (
                    <ListItem>Aucun repas commandé pour l'instant.</ListItem>
                ) : (
                    sortedDates.map((date, index) => (
                        <Box key={index} mb={6}>
                            <Heading as="h4" size="sm" color="teal.500" mb={2}>
                                {date.toLowerCase()}
                            </Heading>
                            <List>
                                {Object.keys(mergedMealsSummary[date]).map((mealName, idx) => (
                                    <ListItem key={idx} fontSize={{ base: "md", lg: "md" }} color="blue.600">
                                        {mealName}: <Badge colorScheme="green" fontSize="1em" ml={1} pr={2}>
                                            {mergedMealsSummary[date][mealName]}
                                        </Badge> plat(s) commandé(s)
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    ))
                )}
            </List>

            {/* Affichage du compteur global des repas */}
            <Box mt={8} mb={12}>
                <Text fontSize={{ base: "md", lg: "lg" }} fontWeight="bold" color="green.600">
                    Total global des repas commandés :{' '}
                    <Badge colorScheme="teal" fontSize="1em" py={1} pl={2} pr={3}>
                        {totalMealsCount}
                    </Badge>
                </Text>
            </Box>
            <Flex
                justifyContent="center"
                alignItems="center"
                my={6}
                spacing={4}
                direction={{ base: "column", md: "row" }}
                gap={{ base: "0", md: "10" }} // Sur mobile (base), empiler les boutons en colonne, sur plus grand (md), en ligne
            >
                {/* Bouton pour copier le texte dans le presse-papiers */}
                <Button
                    colorScheme="blue"
                    onClick={copyToClipboard}
                    isLoading={isCopying}
                    loadingText="Copie..."
                    mb={{ base: 4, md: 0 }}  // Ajoute une marge en bas quand les boutons sont en colonne
                >
                    Copier pour email
                </Button>

                {/* Bouton pour télécharger les résultats en PDF */}
                <Button colorScheme="teal" onClick={downloadPDF}>
                    Télécharger en PDF
                </Button>
            </Flex>
        </Box>
    );
};

export default TotalMeals;
